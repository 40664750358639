/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as LoginRouteImport } from "./routes/login/route";
import { Route as AuthRouteImport } from "./routes/_auth/route";
import { Route as IndexImport } from "./routes/index";
import { Route as LoginIndexImport } from "./routes/login/index";
import { Route as AuthSettingsImport } from "./routes/_auth/settings";
import { Route as AuthDashboardImport } from "./routes/_auth/dashboard";
import { Route as LoginResetPasswordIndexImport } from "./routes/login/resetPassword.index";
import { Route as AuthDossierDossierIdRouteImport } from "./routes/_auth/dossier/$dossierId/route";
import { Route as AuthDossierDossierIdWorkflowsImport } from "./routes/_auth/dossier/$dossierId/workflows";
import { Route as AuthDossierDossierIdStorageImport } from "./routes/_auth/dossier/$dossierId/storage";
import { Route as AuthDossierDossierIdLogsImport } from "./routes/_auth/dossier/$dossierId/logs";
import { Route as AuthDossierDossierIdEditImport } from "./routes/_auth/dossier/$dossierId/edit";
import { Route as AuthDossierDossierIdDocumentIndexImport } from "./routes/_auth/dossier/$dossierId/document.index";
import { Route as LoginResetPasswordConfirmUidTokenImport } from "./routes/login/resetPassword.confirm.$uid.$token";

// Create Virtual Routes

const AuthEditorDocumentIdLazyImport = createFileRoute(
  "/_auth/editor/$documentId",
)();
const AuthDossierDossierIdPdfDossierActionIdLazyImport = createFileRoute(
  "/_auth/dossier/$dossierId/pdf/$dossierActionId",
)();
const AuthDossierDossierIdDocumentDocumentIdLazyImport = createFileRoute(
  "/_auth/dossier/$dossierId/document/$documentId",
)();

// Create/Update Routes

const LoginRouteRoute = LoginRouteImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any);

const AuthRouteRoute = AuthRouteImport.update({
  id: "/_auth",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const LoginIndexRoute = LoginIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => LoginRouteRoute,
} as any);

const AuthSettingsRoute = AuthSettingsImport.update({
  id: "/settings",
  path: "/settings",
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthDashboardRoute = AuthDashboardImport.update({
  id: "/dashboard",
  path: "/dashboard",
  getParentRoute: () => AuthRouteRoute,
} as any);

const LoginResetPasswordIndexRoute = LoginResetPasswordIndexImport.update({
  id: "/resetPassword/",
  path: "/resetPassword/",
  getParentRoute: () => LoginRouteRoute,
} as any);

const AuthEditorDocumentIdLazyRoute = AuthEditorDocumentIdLazyImport.update({
  id: "/editor/$documentId",
  path: "/editor/$documentId",
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import("./routes/_auth/editor.$documentId.lazy").then((d) => d.Route),
);

const AuthDossierDossierIdRouteRoute = AuthDossierDossierIdRouteImport.update({
  id: "/dossier/$dossierId",
  path: "/dossier/$dossierId",
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthDossierDossierIdWorkflowsRoute =
  AuthDossierDossierIdWorkflowsImport.update({
    id: "/workflows",
    path: "/workflows",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any);

const AuthDossierDossierIdStorageRoute =
  AuthDossierDossierIdStorageImport.update({
    id: "/storage",
    path: "/storage",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any);

const AuthDossierDossierIdLogsRoute = AuthDossierDossierIdLogsImport.update({
  id: "/logs",
  path: "/logs",
  getParentRoute: () => AuthDossierDossierIdRouteRoute,
} as any);

const AuthDossierDossierIdEditRoute = AuthDossierDossierIdEditImport.update({
  id: "/edit",
  path: "/edit",
  getParentRoute: () => AuthDossierDossierIdRouteRoute,
} as any);

const AuthDossierDossierIdDocumentIndexRoute =
  AuthDossierDossierIdDocumentIndexImport.update({
    id: "/document/",
    path: "/document/",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any);

const AuthDossierDossierIdPdfDossierActionIdLazyRoute =
  AuthDossierDossierIdPdfDossierActionIdLazyImport.update({
    id: "/pdf/$dossierActionId",
    path: "/pdf/$dossierActionId",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any).lazy(() =>
    import("./routes/_auth/dossier/$dossierId/pdf.$dossierActionId.lazy").then(
      (d) => d.Route,
    ),
  );

const AuthDossierDossierIdDocumentDocumentIdLazyRoute =
  AuthDossierDossierIdDocumentDocumentIdLazyImport.update({
    id: "/document/$documentId",
    path: "/document/$documentId",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any).lazy(() =>
    import("./routes/_auth/dossier/$dossierId/document.$documentId.lazy").then(
      (d) => d.Route,
    ),
  );

const LoginResetPasswordConfirmUidTokenRoute =
  LoginResetPasswordConfirmUidTokenImport.update({
    id: "/resetPassword/confirm/$uid/$token",
    path: "/resetPassword/confirm/$uid/$token",
    getParentRoute: () => LoginRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_auth": {
      id: "/_auth";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AuthRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/login": {
      id: "/login";
      path: "/login";
      fullPath: "/login";
      preLoaderRoute: typeof LoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_auth/dashboard": {
      id: "/_auth/dashboard";
      path: "/dashboard";
      fullPath: "/dashboard";
      preLoaderRoute: typeof AuthDashboardImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/_auth/settings": {
      id: "/_auth/settings";
      path: "/settings";
      fullPath: "/settings";
      preLoaderRoute: typeof AuthSettingsImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/login/": {
      id: "/login/";
      path: "/";
      fullPath: "/login/";
      preLoaderRoute: typeof LoginIndexImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId": {
      id: "/_auth/dossier/$dossierId";
      path: "/dossier/$dossierId";
      fullPath: "/dossier/$dossierId";
      preLoaderRoute: typeof AuthDossierDossierIdRouteImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/_auth/editor/$documentId": {
      id: "/_auth/editor/$documentId";
      path: "/editor/$documentId";
      fullPath: "/editor/$documentId";
      preLoaderRoute: typeof AuthEditorDocumentIdLazyImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/login/resetPassword/": {
      id: "/login/resetPassword/";
      path: "/resetPassword";
      fullPath: "/login/resetPassword";
      preLoaderRoute: typeof LoginResetPasswordIndexImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId/edit": {
      id: "/_auth/dossier/$dossierId/edit";
      path: "/edit";
      fullPath: "/dossier/$dossierId/edit";
      preLoaderRoute: typeof AuthDossierDossierIdEditImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/logs": {
      id: "/_auth/dossier/$dossierId/logs";
      path: "/logs";
      fullPath: "/dossier/$dossierId/logs";
      preLoaderRoute: typeof AuthDossierDossierIdLogsImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/storage": {
      id: "/_auth/dossier/$dossierId/storage";
      path: "/storage";
      fullPath: "/dossier/$dossierId/storage";
      preLoaderRoute: typeof AuthDossierDossierIdStorageImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/workflows": {
      id: "/_auth/dossier/$dossierId/workflows";
      path: "/workflows";
      fullPath: "/dossier/$dossierId/workflows";
      preLoaderRoute: typeof AuthDossierDossierIdWorkflowsImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/login/resetPassword/confirm/$uid/$token": {
      id: "/login/resetPassword/confirm/$uid/$token";
      path: "/resetPassword/confirm/$uid/$token";
      fullPath: "/login/resetPassword/confirm/$uid/$token";
      preLoaderRoute: typeof LoginResetPasswordConfirmUidTokenImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId/document/$documentId": {
      id: "/_auth/dossier/$dossierId/document/$documentId";
      path: "/document/$documentId";
      fullPath: "/dossier/$dossierId/document/$documentId";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentDocumentIdLazyImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/pdf/$dossierActionId": {
      id: "/_auth/dossier/$dossierId/pdf/$dossierActionId";
      path: "/pdf/$dossierActionId";
      fullPath: "/dossier/$dossierId/pdf/$dossierActionId";
      preLoaderRoute: typeof AuthDossierDossierIdPdfDossierActionIdLazyImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/document/": {
      id: "/_auth/dossier/$dossierId/document/";
      path: "/document";
      fullPath: "/dossier/$dossierId/document";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentIndexImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
  }
}

// Create and export the route tree

interface AuthDossierDossierIdRouteRouteChildren {
  AuthDossierDossierIdEditRoute: typeof AuthDossierDossierIdEditRoute;
  AuthDossierDossierIdLogsRoute: typeof AuthDossierDossierIdLogsRoute;
  AuthDossierDossierIdStorageRoute: typeof AuthDossierDossierIdStorageRoute;
  AuthDossierDossierIdWorkflowsRoute: typeof AuthDossierDossierIdWorkflowsRoute;
  AuthDossierDossierIdDocumentDocumentIdLazyRoute: typeof AuthDossierDossierIdDocumentDocumentIdLazyRoute;
  AuthDossierDossierIdPdfDossierActionIdLazyRoute: typeof AuthDossierDossierIdPdfDossierActionIdLazyRoute;
  AuthDossierDossierIdDocumentIndexRoute: typeof AuthDossierDossierIdDocumentIndexRoute;
}

const AuthDossierDossierIdRouteRouteChildren: AuthDossierDossierIdRouteRouteChildren =
  {
    AuthDossierDossierIdEditRoute: AuthDossierDossierIdEditRoute,
    AuthDossierDossierIdLogsRoute: AuthDossierDossierIdLogsRoute,
    AuthDossierDossierIdStorageRoute: AuthDossierDossierIdStorageRoute,
    AuthDossierDossierIdWorkflowsRoute: AuthDossierDossierIdWorkflowsRoute,
    AuthDossierDossierIdDocumentDocumentIdLazyRoute:
      AuthDossierDossierIdDocumentDocumentIdLazyRoute,
    AuthDossierDossierIdPdfDossierActionIdLazyRoute:
      AuthDossierDossierIdPdfDossierActionIdLazyRoute,
    AuthDossierDossierIdDocumentIndexRoute:
      AuthDossierDossierIdDocumentIndexRoute,
  };

const AuthDossierDossierIdRouteRouteWithChildren =
  AuthDossierDossierIdRouteRoute._addFileChildren(
    AuthDossierDossierIdRouteRouteChildren,
  );

interface AuthRouteRouteChildren {
  AuthDashboardRoute: typeof AuthDashboardRoute;
  AuthSettingsRoute: typeof AuthSettingsRoute;
  AuthDossierDossierIdRouteRoute: typeof AuthDossierDossierIdRouteRouteWithChildren;
  AuthEditorDocumentIdLazyRoute: typeof AuthEditorDocumentIdLazyRoute;
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthDashboardRoute: AuthDashboardRoute,
  AuthSettingsRoute: AuthSettingsRoute,
  AuthDossierDossierIdRouteRoute: AuthDossierDossierIdRouteRouteWithChildren,
  AuthEditorDocumentIdLazyRoute: AuthEditorDocumentIdLazyRoute,
};

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
);

interface LoginRouteRouteChildren {
  LoginIndexRoute: typeof LoginIndexRoute;
  LoginResetPasswordIndexRoute: typeof LoginResetPasswordIndexRoute;
  LoginResetPasswordConfirmUidTokenRoute: typeof LoginResetPasswordConfirmUidTokenRoute;
}

const LoginRouteRouteChildren: LoginRouteRouteChildren = {
  LoginIndexRoute: LoginIndexRoute,
  LoginResetPasswordIndexRoute: LoginResetPasswordIndexRoute,
  LoginResetPasswordConfirmUidTokenRoute:
    LoginResetPasswordConfirmUidTokenRoute,
};

const LoginRouteRouteWithChildren = LoginRouteRoute._addFileChildren(
  LoginRouteRouteChildren,
);

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof AuthRouteRouteWithChildren;
  "/login": typeof LoginRouteRouteWithChildren;
  "/dashboard": typeof AuthDashboardRoute;
  "/settings": typeof AuthSettingsRoute;
  "/login/": typeof LoginIndexRoute;
  "/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/editor/$documentId": typeof AuthEditorDocumentIdLazyRoute;
  "/login/resetPassword": typeof LoginResetPasswordIndexRoute;
  "/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdLazyRoute;
  "/dossier/$dossierId/pdf/$dossierActionId": typeof AuthDossierDossierIdPdfDossierActionIdLazyRoute;
  "/dossier/$dossierId/document": typeof AuthDossierDossierIdDocumentIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof AuthRouteRouteWithChildren;
  "/dashboard": typeof AuthDashboardRoute;
  "/settings": typeof AuthSettingsRoute;
  "/login": typeof LoginIndexRoute;
  "/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/editor/$documentId": typeof AuthEditorDocumentIdLazyRoute;
  "/login/resetPassword": typeof LoginResetPasswordIndexRoute;
  "/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdLazyRoute;
  "/dossier/$dossierId/pdf/$dossierActionId": typeof AuthDossierDossierIdPdfDossierActionIdLazyRoute;
  "/dossier/$dossierId/document": typeof AuthDossierDossierIdDocumentIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_auth": typeof AuthRouteRouteWithChildren;
  "/login": typeof LoginRouteRouteWithChildren;
  "/_auth/dashboard": typeof AuthDashboardRoute;
  "/_auth/settings": typeof AuthSettingsRoute;
  "/login/": typeof LoginIndexRoute;
  "/_auth/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/_auth/editor/$documentId": typeof AuthEditorDocumentIdLazyRoute;
  "/login/resetPassword/": typeof LoginResetPasswordIndexRoute;
  "/_auth/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/_auth/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/_auth/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/_auth/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/_auth/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdLazyRoute;
  "/_auth/dossier/$dossierId/pdf/$dossierActionId": typeof AuthDossierDossierIdPdfDossierActionIdLazyRoute;
  "/_auth/dossier/$dossierId/document/": typeof AuthDossierDossierIdDocumentIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/login"
    | "/dashboard"
    | "/settings"
    | "/login/"
    | "/dossier/$dossierId"
    | "/editor/$documentId"
    | "/login/resetPassword"
    | "/dossier/$dossierId/edit"
    | "/dossier/$dossierId/logs"
    | "/dossier/$dossierId/storage"
    | "/dossier/$dossierId/workflows"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/dossier/$dossierId/document/$documentId"
    | "/dossier/$dossierId/pdf/$dossierActionId"
    | "/dossier/$dossierId/document";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/dashboard"
    | "/settings"
    | "/login"
    | "/dossier/$dossierId"
    | "/editor/$documentId"
    | "/login/resetPassword"
    | "/dossier/$dossierId/edit"
    | "/dossier/$dossierId/logs"
    | "/dossier/$dossierId/storage"
    | "/dossier/$dossierId/workflows"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/dossier/$dossierId/document/$documentId"
    | "/dossier/$dossierId/pdf/$dossierActionId"
    | "/dossier/$dossierId/document";
  id:
    | "__root__"
    | "/"
    | "/_auth"
    | "/login"
    | "/_auth/dashboard"
    | "/_auth/settings"
    | "/login/"
    | "/_auth/dossier/$dossierId"
    | "/_auth/editor/$documentId"
    | "/login/resetPassword/"
    | "/_auth/dossier/$dossierId/edit"
    | "/_auth/dossier/$dossierId/logs"
    | "/_auth/dossier/$dossierId/storage"
    | "/_auth/dossier/$dossierId/workflows"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/_auth/dossier/$dossierId/document/$documentId"
    | "/_auth/dossier/$dossierId/pdf/$dossierActionId"
    | "/_auth/dossier/$dossierId/document/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AuthRouteRoute: typeof AuthRouteRouteWithChildren;
  LoginRouteRoute: typeof LoginRouteRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRouteRoute: AuthRouteRouteWithChildren,
  LoginRouteRoute: LoginRouteRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/dashboard",
        "/_auth/settings",
        "/_auth/dossier/$dossierId",
        "/_auth/editor/$documentId"
      ]
    },
    "/login": {
      "filePath": "login/route.tsx",
      "children": [
        "/login/",
        "/login/resetPassword/",
        "/login/resetPassword/confirm/$uid/$token"
      ]
    },
    "/_auth/dashboard": {
      "filePath": "_auth/dashboard.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings": {
      "filePath": "_auth/settings.tsx",
      "parent": "/_auth"
    },
    "/login/": {
      "filePath": "login/index.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId": {
      "filePath": "_auth/dossier/$dossierId/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/dossier/$dossierId/edit",
        "/_auth/dossier/$dossierId/logs",
        "/_auth/dossier/$dossierId/storage",
        "/_auth/dossier/$dossierId/workflows",
        "/_auth/dossier/$dossierId/document/$documentId",
        "/_auth/dossier/$dossierId/pdf/$dossierActionId",
        "/_auth/dossier/$dossierId/document/"
      ]
    },
    "/_auth/editor/$documentId": {
      "filePath": "_auth/editor.$documentId.lazy.tsx",
      "parent": "/_auth"
    },
    "/login/resetPassword/": {
      "filePath": "login/resetPassword.index.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId/edit": {
      "filePath": "_auth/dossier/$dossierId/edit.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/logs": {
      "filePath": "_auth/dossier/$dossierId/logs.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/storage": {
      "filePath": "_auth/dossier/$dossierId/storage.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/workflows": {
      "filePath": "_auth/dossier/$dossierId/workflows.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/login/resetPassword/confirm/$uid/$token": {
      "filePath": "login/resetPassword.confirm.$uid.$token.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId/document/$documentId": {
      "filePath": "_auth/dossier/$dossierId/document.$documentId.lazy.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/pdf/$dossierActionId": {
      "filePath": "_auth/dossier/$dossierId/pdf.$dossierActionId.lazy.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/document/": {
      "filePath": "_auth/dossier/$dossierId/document.index.tsx",
      "parent": "/_auth/dossier/$dossierId"
    }
  }
}
ROUTE_MANIFEST_END */
