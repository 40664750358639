import { queryOptions } from "@tanstack/react-query";

import { fetchUserBackground } from "@dokworks/fetch/user";

import { api } from "@/utils/fetch/api";
import { getImageUrl } from "@/utils/helpers/asset.helper";

const background = getImageUrl("background/background_doklegal.jpeg");

export const userBackgroundQueryOptions = (authenticated?: boolean) =>
  queryOptions<string>({
    queryKey: ["user", "background"],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      if (!authenticated) return background;

      const url = await fetchUserBackground(api, {
        signal,
      })
        .then(async (res) => {
          if (res.status === 204) return background;

          const url = URL.createObjectURL(await res.blob());

          return url;
        })
        .catch(() => background);

      return url;
    },
    initialData: background,
    retry: 0,
  });
