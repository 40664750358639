import { createFileRoute } from "@tanstack/react-router";

import { WorkflowFileList } from "@/components/workflow/workflowFileList";

export const Route = createFileRoute("/_auth/dossier/$dossierId/storage")({
  component: StorageComponent,
});

function StorageComponent() {
  const dossierId = Route.useParams({ select: (params) => params.dossierId });
  return (
    <div className="floating flex flex-1 flex-col gap-4 rounded-tl-sm px-6">
      <h1 className="mx-auto mt-8 w-full font-display text-3xl font-semibold wide:max-w-xlarge">
        Bestandsbeheer
      </h1>
      <div className="mx-auto mt-2 w-full space-y-2 rounded-md border border bg-canvas px-1 pb-1 pt-2 shadow-sm wide:max-w-xlarge">
        <h2 className="mt-2 text-center font-display text-lg">Bestanden</h2>
        <WorkflowFileList dossierId={dossierId} />
      </div>
    </div>
  );
}
