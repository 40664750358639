import { queryOptions } from "@tanstack/react-query";

import { fetchUser } from "@dokworks/fetch/user";

import { api } from "@/utils/fetch/api";

export const userQueryOptions = queryOptions({
  queryKey: ["user"],
  queryFn: ({ signal }) => fetchUser(api, { signal }),
});
