import { useEffect, useRef, useState } from "react";

import { createFileRoute, useRouterState } from "@tanstack/react-router";

import { cn } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

export const Route = createFileRoute("/_auth/settings")({
  component: SettingsComponent,
});

function SettingsComponent() {
  const routeIsLoading = useRouterState({
    select: (state) => state.isLoading || state.isTransitioning,
  });

  const [iframeIsLoading, setIframeIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIframeIsLoading(true);
    iframeRef.current!.src = `/vue/user/self`;
  }, []);

  const isLoading = routeIsLoading || iframeIsLoading;

  return (
    <>
      <div
        className={cn(
          "floating flex flex-1 items-center justify-center gap-2",
          !isLoading && "hidden",
        )}
      >
        <Spinner className="text-fg-accent" /> Loading...
      </div>
      <iframe
        ref={iframeRef}
        className={cn("floating flex-1", isLoading && "hidden")}
        onLoad={() => {
          setIframeIsLoading(false);
        }}
      ></iframe>
    </>
  );
}
