import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { ky } from "@dokworks/fetch";

const sort = ["asc", "desc"] as const;
const sortBySchema = z.enum(sort).catch("asc");

type SortMode = z.infer<typeof sortBySchema>;

const paginationSchema = z.object({
  limit: z.number().int().nonnegative().catch(30),
  offset: z.number().int().nonnegative().catch(0),
});

type Paginated = z.infer<typeof paginationSchema>;

function mergeParams<TOpts extends Record<string, unknown>>(
  opts: TOpts,
  url?: string | null,
): TOpts {
  let search = "";
  if (url) {
    try {
      // if url is a valid url. parse it to a search string
      search = new URL(url).search;
    } catch {
      search = url;
    }
  }

  const searchParams = url
    ? Object.fromEntries(new URLSearchParams(search))
    : undefined;

  return {
    ...opts,
    ...searchParams,
  };
}

type MimeDB = Record<
  string,
  {
    source: "apache" | "iana" | "nginx";
    charset?: string;
    compressible: boolean;
    extensions?: string[];
  }
>;

const mimeDBQueryOptions = (releaseTag?: string) =>
  queryOptions({
    queryKey: ["mime-db", ...(releaseTag ? [releaseTag] : [])],
    staleTime: Infinity,
    queryFn: async ({ signal }) => {
      ky.get("https://cdn.jsdelivr.net/gh/jshttp/mime-db@1.53.0", {
        signal,
      });
    },
  });

export {
  sortBySchema as sortBy,
  type SortMode,
  paginationSchema,
  type Paginated,
  mergeParams,
  mimeDBQueryOptions,
  type MimeDB,
};
