import { FC, type PropsWithChildren } from "react";

import { MatchRoute, useMatchRoute, useParams } from "@tanstack/react-router";
import {
  DatabaseZapIcon,
  FileCheckIcon,
  FolderEditIcon,
  FolderSearch2Icon,
  RouteIcon,
} from "lucide-react";
import { Group, Toolbar, TooltipTrigger } from "react-aria-components";

import { cn } from "@dokworks/shared";
import { styledToggleButton, Tooltip, UnstyledLink } from "@dokworks/ui";

export const ContextNavigationBar: FC<PropsWithChildren> = () => {
  return (
    <Toolbar
      aria-label="Acties"
      className="box-content flex w-max min-w-12 flex-col flex-nowrap gap-1 pr-2"
      orientation="vertical"
    >
      <div
        aria-label="Context bar controls"
        className="flex flex-col gap-1 p-1"
      >
        <div className="size-10 select-none"></div>
        {/* <Button
          aria-label="Open context inspector"
          variant="floating"
          size="icon-medium"
          isDisabled
          className="data-[hovered]:bg-canvas/60"
        >
          <PanelRight24Filled className="size-5" />
        </Button> */}
      </div>
      <MatchRoute to="/dossier/$dossierId" fuzzy>
        <Group
          aria-label="Dossier tabbladen"
          className="floating flex flex-col gap-1 rounded bg-canvas/60 p-1"
        >
          <DocumentManagerButton />
          <EditButton />
          <WorkflowButton />
          <StorageButton />
          <AuditLogButton />
        </Group>
      </MatchRoute>
    </Toolbar>
  );
};

function DocumentManagerButton() {
  const matchRoute = useMatchRoute();

  const dossierId = useDossierId();

  if (!dossierId) return null;

  const isSelected = !!matchRoute({
    to: "/dossier/$dossierId/document",
    fuzzy: true,
  });

  const label = "Documentbeheer";

  return (
    <TooltipTrigger>
      <UnstyledLink
        aria-label={label}
        href={"/dossier/" + dossierId + "/document"}
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            size: "icon-medium",
            variant: "ghost",
            isSelected: isSelected,
            className: cn({
              "transition-all ease-productive": isSelected,
            }),
          })
        }
      >
        <FileCheckIcon className="size-5" />
      </UnstyledLink>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
}

function EditButton() {
  const matchRoute = useMatchRoute();

  const dossierId = useDossierId();

  if (!dossierId) return null;

  const isSelected = !!matchRoute({
    to: "/dossier/$dossierId/edit",
  });

  return (
    <TooltipTrigger>
      <UnstyledLink
        aria-label="Edit dossier"
        href={"/dossier/" + dossierId + "/edit?page=0&section=0"}
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            size: "icon-medium",
            variant: "ghost",
            isSelected: isSelected,
            className: cn("rounded", {
              "transition-all ease-productive": isSelected,
            }),
          })
        }
      >
        <FolderEditIcon className="size-5" />
      </UnstyledLink>
      <Tooltip placement="left">Dossier gegevens aanpassen</Tooltip>
    </TooltipTrigger>
  );
}

function StorageButton() {
  const matchRoute = useMatchRoute();

  const dossierId = useDossierId();

  if (!dossierId) return;

  const isSelected = !!matchRoute({
    to: "/dossier/$dossierId/storage",
    fuzzy: true,
  });

  const label = "Bestandsbeheer";

  return (
    <TooltipTrigger>
      <UnstyledLink
        aria-label={label}
        href={"/dossier/" + dossierId + "/storage"}
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            size: "icon-medium",
            variant: "ghost",
            isSelected,
            className: cn("rounded", {
              "transition-all ease-productive": isSelected,
            }),
          })
        }
      >
        <FolderSearch2Icon className="size-5" />
      </UnstyledLink>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
}

function WorkflowButton() {
  const matchRoute = useMatchRoute();

  const dossierId = useDossierId();

  if (!dossierId) return;

  const isSelected = !!matchRoute({
    to: "/dossier/$dossierId/workflows",
    fuzzy: true,
  });

  return (
    <TooltipTrigger>
      <UnstyledLink
        aria-label="User Settings"
        href={"/dossier/" + dossierId + "/workflows"}
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            size: "icon-medium",
            variant: "ghost",
            isSelected,
            className: cn("rounded", {
              "transition-all ease-productive": isSelected,
            }),
          })
        }
      >
        <RouteIcon className="size-5" />
      </UnstyledLink>
      <Tooltip placement="left">Workflow inzicht</Tooltip>
    </TooltipTrigger>
  );
}
function AuditLogButton() {
  const matchRoute = useMatchRoute();
  const dossierId = useDossierId();

  if (!dossierId) return;

  const isSelected = !!matchRoute({
    to: "/dossier/$dossierId/logs",
    fuzzy: true,
  });

  return (
    <TooltipTrigger>
      <UnstyledLink
        aria-label="Audit logs"
        href={"/dossier/" + dossierId + "/logs"}
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            size: "icon-medium",
            variant: "ghost",
            isSelected,
            className: cn("rounded", {
              "transition-all ease-productive": isSelected,
            }),
          })
        }
      >
        <DatabaseZapIcon className="size-5" />
      </UnstyledLink>
      <Tooltip placement="left">Dossier logs</Tooltip>
    </TooltipTrigger>
  );
}

function useDossierId() {
  const id = useParams({
    strict: false,
    select: (params) => params.dossierId,
  });

  return id;
}
