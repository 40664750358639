import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import {
  WorkflowType,
  type WorkflowAutomation,
  type WorkflowManual,
} from "@dokworks/shared";

import { workflowListQueryOptions } from "@/utils/fetch/workflow";
import { WorkflowAutomationTable } from "@/components/workflow/table/automation.table.workflow";
import { WorkflowManualTable } from "@/components/workflow/table/manual.table.workflow";

export const Route = createFileRoute("/_auth/dossier/$dossierId/workflows")({
  component: WorkflowsComponent,
});

function WorkflowsComponent() {
  const dossierId = Route.useParams({ select: (params) => params.dossierId });

  const { data: workflowAutomationList } = useQuery(
    workflowListQueryOptions({
      type: WorkflowType.AUTO,
      dossierId,
    }),
  );
  const { data: workflowManualList } = useQuery(
    workflowListQueryOptions({
      type: WorkflowType.MANUAL,
      dossierId,
    }),
  );
  return (
    <div className="floating flex flex-1 flex-col gap-4 rounded-tl-sm px-6">
      <h1 className="mt-8 font-display text-3xl font-semibold">
        Workflow inzicht
      </h1>
      <div className={"mt-2 rounded-md border bg-canvas pt-4 shadow-sm"}>
        <h2 className="px-6 text-center font-display text-lg font-semibold">
          Automatisch
        </h2>
        <WorkflowAutomationTable
          items={(workflowAutomationList as WorkflowAutomation[]) ?? []}
        />
      </div>
      <div className={"mt-2 rounded-md border bg-canvas pt-4 shadow-sm"}>
        <h2 className="px-6 text-center font-display text-lg font-semibold">
          Handmatig
        </h2>
        <WorkflowManualTable
          items={(workflowManualList as WorkflowManual[]) ?? []}
        />
      </div>
    </div>
  );
}
