import {
  createFileRoute,
  Outlet,
  redirect,
  useLocation,
  useRouter,
  useRouterState,
} from "@tanstack/react-router";
import { TabPanel, Tabs } from "react-aria-components";

import { cn } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

import { useAuth } from "@/auth";
import { ContextNavigationBar } from "@/routes/_auth/-components/contextNavigationBar";
import { Header } from "@/routes/_auth/-components/header";
import { NavigationBar } from "@/routes/_auth/-components/navigationBar";
import { userQueryOptions } from "@/utils/fetch/user";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },

  loader: (opts) => opts.context.queryClient.ensureQueryData(userQueryOptions),

  component: AuthLayout,
});

function AuthLayout() {
  const router = useRouter();
  const isLoading = useRouterState({
    select: (state) => state.isTransitioning,
  });

  const pathname = useLocation({
    select: (state) => state.pathname,
  });
  const navigate = Route.useNavigate();
  const auth = useAuth();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      auth.logout().then(() => {
        router.invalidate().finally(() => {
          navigate({ to: "/login" });
        });
      });
    }
  };

  return (
    <div className="relative flex flex-1 flex-col pb-4">
      <Header handleLogout={handleLogout} />
      <Tabs
        selectedKey={pathname}
        orientation="vertical"
        keyboardActivation="manual"
        className="flex flex-1"
      >
        <NavigationBar />
        <TabPanel
          id={pathname}
          className="flex flex-1 gap-1 outline-none ring-brd-accent-emphasis focus-visible:ring-2"
        >
          <Outlet />
          <ContextNavigationBar />
        </TabPanel>
      </Tabs>
      <div
        className={cn(
          "absolute inset-x-0 bottom-4 top-12 mx-14 items-center justify-center gap-2 rounded-md font-semibold",
          isLoading ? "flex" : "hidden",
        )}
      >
        <Spinner className="text-fg-accent" size="medium" /> Loading...
      </div>
    </div>
  );
}
