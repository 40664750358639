import { useState, type FormEvent } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { Form } from "react-aria-components";

import { isHTTPError } from "@dokworks/fetch";
import { requestPasswordReset } from "@dokworks/fetch/auth";
import {
  Button,
  Input,
  Spinner,
  styledButton,
  TextField,
  UnstyledLink,
} from "@dokworks/ui";

import { api } from "@/utils/fetch/api";

export const Route = createFileRoute("/login/resetPassword/")({
  component: ResetPasswordComponent,
});

function ResetPasswordComponent() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState("");
  const [submitError, setSubmitError] = useState("");

  return (
    <>
      {response && (
        <p className="space-y-1 rounded-md border border-brd-success-emphasis bg-canvas-success-muted px-4 py-2 text-center text-sm text-fg-success">
          {response}
        </p>
      )}
      <Form className="flex flex-col pt-12" onSubmit={onFormSubmit}>
        <fieldset
          className="flex flex-1 flex-col gap-4"
          disabled={isSubmitting}
        >
          <TextField
            label="Emailadres"
            description="Vul hier het emailadres van uw account in."
            isRequired
            type="email"
          >
            <Input
              id="email-input"
              name="email"
              placeholder="gebruiker@dok.legal"
              type="email"
              autoComplete="email"
              className="w-full"
            />
          </TextField>
          {submitError && (
            <p className="text-sm text-fg-danger">{submitError}</p>
          )}

          <span className="mt-2 flex gap-2">
            <UnstyledLink
              className={(renderProps) =>
                styledButton({
                  ...renderProps,
                  variant: "ghost",
                  className: "text-fg-link",
                  size: "large",
                })
              }
              href="/login"
            >
              Ga terug
            </UnstyledLink>
            <Button
              type="submit"
              variant="accent"
              size="large"
              className="flex-1"
              onPress={() => {
                setSubmitError("");
                setResponse("");
              }}
            >
              {isSubmitting ? (
                <>
                  <Spinner /> Loading...
                </>
              ) : (
                "Vraag nieuw wachtwoord aan"
              )}
            </Button>
          </span>
        </fieldset>
      </Form>
    </>
  );

  async function onFormSubmit(ev: FormEvent<HTMLFormElement>) {
    setSubmitError("");
    setResponse("");

    try {
      ev.preventDefault();
      const form = ev.currentTarget;
      const data = Object.fromEntries(new FormData(form));
      const email = data.email.toString();

      if (!email) return;

      setIsSubmitting(true);
      await requestPasswordReset(email, api);
      setResponse("Wachtwoord vernieuwingsverzoek is verstuurd!");
      form.reset();
    } catch (error) {
      if (isHTTPError(error)) {
        setSubmitError(await error.response.json());
      }
      console.error(error);
    }

    setIsSubmitting(false);
  }
}
