import type { ComponentProps, FC } from "react";

import { cn } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

import { Logo } from "@/components/logo";

export type FullPageLoaderProps = ComponentProps<"div"> & {
  withLogo?: boolean;
};

export const FullPageLoader: FC<FullPageLoaderProps> = ({
  withLogo,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      "flex h-full flex-1 items-center justify-center",
      props.className,
    )}
  >
    {withLogo ? (
      <div className="flex h-full flex-col items-center justify-center gap-4 px-4 wide:px-6">
        <Logo className="h-16 regular:h-24" />
        <p className="flex items-center gap-2  font-semibold narrow:text-sm">
          <Spinner size="small" className="text-fg-accent" />
          De app wordt ingeladen ..
        </p>
        <p className="ml-2 narrow:text-sm">Even geduld alstublieft.</p>
      </div>
    ) : (
      <Spinner size="large" className="text-fg-accent" />
    )}
  </div>
);
