import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { FileInputIcon, PlusIcon, Trash2Icon } from "lucide-react";
import type { Key } from "react-aria";
import { Selection } from "react-stately";

import type { ResponsePromise } from "@dokworks/fetch";
import {
  Button,
  Cell,
  Column,
  Row,
  Spinner,
  Table,
  TableBody,
  TableHeader,
} from "@dokworks/ui";

import { api } from "@/utils/fetch/api";
import {
  dossierDocumentListQueryOptions,
  dossierDocumentQueryOptions,
} from "@/utils/fetch/dossier";

export const Route = createFileRoute("/_auth/dossier/$dossierId/document/")({
  component: DossierIndexComponent,
});

function DossierIndexComponent() {
  const navigate = Route.useNavigate();
  const { dossierId } = Route.useParams();

  const [selection, setSelection] = useState<Selection>(new Set());

  const queryClient = useQueryClient();
  const { data } = useQuery(dossierDocumentListQueryOptions(dossierId));

  const { mutate, isPending } = useMutation({
    mutationFn: async (dossierId: string) => {
      const res = await api.post("document/create/", {
        json: { dossier_id: dossierId },
      });

      const { documentId } = await res.json<{ documentId: string }>();

      return documentId;
    },
    onSuccess: (documentId, dossierId) => {
      queryClient.invalidateQueries(dossierDocumentListQueryOptions(dossierId));
      queryClient.invalidateQueries(dossierDocumentQueryOptions(documentId));
    },
  });

  const { mutate: deleteMutate, isPending: isPendingDelete } = useMutation({
    mutationFn: async (documentIds: Iterable<Key>) => {
      const promises: ResponsePromise[] = [];

      for (const documentId of documentIds) {
        promises.push(api.delete(`document/${documentId}/`));
      }

      await Promise.all(promises);
    },
    onSettled: (_, err, documentIds) => {
      console.error(err);
      queryClient.invalidateQueries(dossierDocumentListQueryOptions(dossierId));

      for (const documentId of documentIds) {
        queryClient.invalidateQueries(
          dossierDocumentQueryOptions(documentId.toString()),
        );
      }
    },
  });

  return (
    <div className="floating flex flex-1 flex-col gap-4 rounded-tl-sm px-6">
      <h1 className="mt-8 font-display text-3xl font-semibold">
        Documentbeheer
      </h1>
      <div className="mt-2 rounded-md border bg-canvas pt-4 shadow-sm">
        <div className="flex justify-between gap-2 px-4">
          <div className="flex gap-2">
            <Button
              isDisabled={
                isPendingDelete || (selection !== "all" && selection.size < 1)
              }
              variant="danger"
              onPress={() => deleteDocuments(selection)}
            >
              Delete selectie
              {isPendingDelete ? (
                <span className="inline-flex size-5 items-center justify-center">
                  <Spinner size="small" />
                </span>
              ) : (
                <Trash2Icon className="size-5" />
              )}
            </Button>
            <Button
              isDisabled={
                selection === "all" ||
                selection.size === 0 ||
                selection.size > 1
              }
              variant="accent"
              onPress={() =>
                openDocument(
                  (selection as Set<Key>).values().next().value!.toString(),
                )
              }
            >
              Open selectie <FileInputIcon className="size-5" />
            </Button>
          </div>
          <Button
            isDisabled={isPending}
            variant="success"
            className="w-max"
            onPress={() => mutate(dossierId)}
          >
            Genereer nieuw document
            {isPending ? (
              <span className="inline-flex size-5 items-center justify-center">
                <Spinner size="small" />
              </span>
            ) : (
              <PlusIcon className="size-5" />
            )}
          </Button>
        </div>
        <h2 className="px-6 text-center font-display text-lg font-semibold">
          Documenten
        </h2>
        <Table
          aria-label="Dossier documenten"
          className="h-[240px]"
          selectionMode="multiple"
          selectionBehavior="toggle"
          selectedKeys={selection}
          onSelectionChange={setSelection}
        >
          <TableHeader>
            <Column isRowHeader textValue="Naam">
              Naam
            </Column>
          </TableHeader>
          <TableBody
            items={data?.results ?? []}
            renderEmptyState={() => (
              <p className="text-center">Geen documenten gevonden.</p>
            )}
          >
            {function renderRow(item) {
              return (
                <Row id={item.id}>
                  <Cell textValue={item.name}>{item.name}</Cell>
                </Row>
              );
            }}
          </TableBody>
        </Table>
      </div>
    </div>
  );

  function openDocument(key: Key) {
    const id = key.toString();
    navigate({ to: id });
  }

  function deleteDocuments(selection: Selection) {
    const keys: Set<Key> =
      selection === "all"
        ? new Set(...(data?.results.map((item) => item.id) ?? []))
        : selection;

    deleteMutate(keys);
  }
}
