import { useMemo, type FC } from "react";

import {
  Document24Filled,
  DocumentImageFilled,
  DocumentPdf24Filled,
} from "@fluentui/react-icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import { DownloadIcon } from "lucide-react";

import {
  formatBytesToReadableSize,
  isNumeric,
  saveBlob,
  toSlug,
  type Model,
} from "@dokworks/shared";
import { GridList, GridListItem, Spinner } from "@dokworks/ui";

import { api } from "@/utils/fetch/api";
import { dossierFilesQueryOptions } from "@/utils/fetch/dossier";

type WorkflowFileListProps = {
  dossierId: string;
  workflowId?: string;
};

interface DossierFileDetail extends Model {
  name: string;

  mimeType: string;
  size: number;
  source: string;

  createdAt: string;
}

const WorkflowFileList: FC<WorkflowFileListProps> = ({
  dossierId,
  workflowId,
}) => {
  const opts = useMemo(
    () => ({ dossierId, workflowId, limit: 12, offset: 0 }),
    [dossierId, workflowId],
  );
  // const mimeDB =
  const { data, isFetching } = useInfiniteQuery(dossierFilesQueryOptions(opts));

  const filteredData = useMemo<DossierFileDetail[]>(
    () =>
      data?.pages.flatMap((page) => {
        if (page.count < 1 || page.results.length < 1) return [];

        const dossierFileDetails: DossierFileDetail[] = [];

        for (const dossierFileDetailList of page.results) {
          if (!workflowId || dossierFileDetailList.workflowRef === workflowId) {
            dossierFileDetailList.files.forEach((detail) => {
              dossierFileDetails.push({
                id: detail.name,
                name: detail.workflowFileType
                  ? toSlug(detail.workflowFileType)
                  : detail.name || "Naam onbekend",
                mimeType: detail.fileType,
                createdAt: detail.created,
                size:
                  "fileSize" in detail && isNumeric(detail.fileSize)
                    ? Number(detail.fileSize)
                    : 0,
                source: detail.file.substring(
                  detail.file.indexOf("workflow_v2"),
                ),
              });
            });
          }
        }

        return dossierFileDetails;
      }) ?? [],
    [data?.pages, workflowId],
  );

  if (isFetching)
    return (
      <div className="flex items-center justify-center gap-2 px-6 py-2 text-sm">
        <Spinner className="text-fg-accent" size="small" /> Loading...
      </div>
    );

  return (
    <GridList
      aria-label="Bestanden"
      items={filteredData}
      onAction={(key) => {
        const item = filteredData.find((item) => item.id === key);
        if (item) {
          handleDownload(item.source, item.name);
        }
      }}
      className="space-y-1"
      renderEmptyState={() => <FileListEmptyState />}
    >
      {(item) => (
        <GridListItem
          id={item.id}
          textValue={item.name}
          className="relative items-center gap-0 rounded-none border-t py-1"
        >
          <DossierFileIcon mimeType={item.mimeType} className="mr-2" />
          <div className="grid flex-1 select-none">
            <div className="flex flex-1 items-center justify-between">
              <span className="select-none font-display text-xs font-semibold">
                Bestandsnaam
              </span>
              <span className="font-mono text-xs font-semibold">
                {formatBytesToReadableSize(item.size)}
              </span>
            </div>
            <div className="flex flex-1 select-none items-center justify-between">
              <span className="text-base font-light">{item.name}</span>
              <span className="max-w-[180px] truncate font-mono text-xs font-semibold">
                {item.mimeType}
              </span>
            </div>
          </div>
          <p className="absolute inset-0 hidden cursor-pointer select-none items-center justify-center text-fg-link group-data-[hovered]/list-item:flex">
            <span className="flex items-center justify-end gap-1 bg-canvas-muted px-3 py-1 underline underline-offset-4">
              Download
              <DownloadIcon className="size-4" />
            </span>
          </p>
        </GridListItem>
      )}
    </GridList>
  );

  async function handleDownload(source: string, fileName: string) {
    const file = await api.get(source).blob();
    saveBlob(file, fileName);
  }
};

function FileListEmptyState() {
  return (
    <p className="rounded-md border bg-canvas-muted px-3 py-2 text-center ">
      Er zijn geen bestanden gevonden.
    </p>
  );
}

function DossierFileIcon({
  mimeType,
  className: _className,
}: {
  mimeType: string;
  className?: string;
}) {
  const className = [
    "size-6 text-fg-accent",
    ...(_className ? [_className] : []),
  ];

  if (mimeType.includes("pdf"))
    return <DocumentPdf24Filled className={className.join(" ")} />;

  if (mimeType.includes("img"))
    return <DocumentImageFilled className={className.join(" ")} />;

  return <Document24Filled className={className.join(" ")} />;
}

export { WorkflowFileList };
